import {
  type Ref,
  ref
} from 'vue'

export interface ApiOptions {
  /** Silent mode. If true, loading will not be toggled. */
  silent?: boolean

  /** Default value for loading state */
  loadingDefault?: boolean
}

export const useApi = (userOptions: ApiOptions = {}) => {
  const apiOptions: ApiOptions = {
    silent: false,
    loadingDefault: false,
    ...userOptions,
  }

  const loading: Ref<boolean> = ref(apiOptions.loadingDefault === true)
  const error: Ref<string | null> = ref(null)

  return {
    loading,
    error,
    request: (url: string, options?: {}) => {
      error.value = null

      if (apiOptions.silent === false) {
        loading.value = true
      }

      const params = new URLSearchParams()

      if (url.endsWith('.json')) {
        params.set('ts', Date.now().toString())
      }

      const paramsStr = params.size ? `?${params.toString()}` : ''

      const requestUrl = `${import.meta.env.VITE_API_ROOT}/${url}${paramsStr}`

      return fetch(requestUrl, options)
        .then(response => {
          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`)
          }

          return response.json()
        })
        .finally(() => {
          loading.value = false
          // if (apiOptions.disableLoadingManually === false) {
          //   loading.value = false
          // }
        })
    },
  }
}
